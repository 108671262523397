<template>
  <div class="d-flex flex-column content landing">
    <div class="flex-grow-1 d-flex flex-md-row flex-column">
      <div class="w-50 promo position-relative d-none d-md-block">
        <div class="middle">
          <Logo class="middle__logo" />
          <h2>{{ $t("general.auth.signUp.title") }}</h2>
        </div>
      </div>

      <div class="w-50 h-100 position-md-relative">
        <div class="mobile-auth">
          <div class="mobile-auth__hero d-md-none">
            <div class="mobile-auth__logo-wrapper">
              <Logo class="mobile-auth__logo" />
            </div>
          </div>

          <div class="mobile-auth__title">
            <span class="mobile-auth__font mobile-auth__font--title">
              {{ $t("general.auth.signUp.title") }}
            </span>
          </div>

          <div class="mobile-auth__content">
            <AppInfo
              class="mb-3"
              text=""
              type="error"
              v-if="errors.collect('_').length"
            >
              <ul class="pl-0">
                <li
                  class="list-unstyled"
                  v-for="errorItem in errors.collect('_')"
                  :key="errorItem"
                >
                  {{ errorItem }}
                </li>
              </ul>
            </AppInfo>

            <form novalidate class="form" @submit.prevent="submitForm">
              <CInput name="_" hidden />
              <CInput
                name="email"
                type="email"
                v-model="email"
                :label="$t('general.auth.signUp.form.email')"
                :rules="'required|email'"
              >
                <template #appendAfter>
                  <transition name="fade" mode="out-in">
                    <IconCheck class="icon icon--check" v-if="email" />
                    <IconFalse class="icon icon--false" v-else />
                  </transition>
                </template>
              </CInput>

              <CInput
                type="password"
                name="password"
                v-model="password"
                :label="$t('general.auth.signUp.form.password')"
                :rules="'required|min:8'"
              >
                <template #appendAfter>
                  <transition name="fade" mode="out-in">
                    <IconCheck class="icon icon--check" v-if="password" />
                    <IconFalse class="icon icon--false" v-else />
                  </transition>
                </template>
              </CInput>

              <CInput
                type="text"
                name="name"
                v-model="name"
                :label="$t('general.auth.signUp.form.name')"
                :rules="'required'"
              >
                <template #appendAfter>
                  <transition name="fade" mode="out-in">
                    <IconCheck class="icon icon--check" v-if="name" />
                    <IconFalse class="icon icon--false" v-else />
                  </transition>
                </template>
              </CInput>

              <i18n
                path="general.auth.signUp.agree.base-text"
                tag="div"
                class="font font--policy mt-2 mb-4 ml-2"
              >
                <template v-slot:terms>
                  <CLink :to="'/terms.html'" target="_blank">
                    {{ $t("general.auth.signUp.agree.terms") }}
                  </CLink>
                </template>

                <template v-slot:policy>
                  <CLink :to="'/privacy.html'" target="_blank">
                    {{ $t("general.auth.signUp.agree.policy") }}
                  </CLink>
                </template>
              </i18n>

              <CButton
                type="submit"
                variant="primary"
                class="w-100 text-uppercase"
                :disabled="hasValidationError"
              >
                {{ $t("general.auth.signUp.button.sign-up") }}
              </CButton>
            </form>

            <div class="d-flex justify-content-between">
<!--              <SocialAuthButton class="w-50 mt-3" auth-variants="twitter">-->
<!--                {{ $t("general.auth.signUp.button.twitter") }}-->
<!--              </SocialAuthButton>-->

              <SocialAuthButton class="w-50 mt-3" auth-variants="google">
                {{ $t("general.auth.signUp.button.google") }}
              </SocialAuthButton>
            </div>

            <div class="benefits">
              <BenefitsList
                :items="benefitsList"
                :title="$t('general.auth.signUp.benefits.title')"
                class="mt-3"
              />
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="border-top p-3">
      <div class="small text-center">
        {{ $t("general.auth.signUp.footer") }}
        <CLink to="/login">{{ $t("general.auth.signUp.button.login") }}</CLink>
      </div>
    </div>
  </div>
</template>

<script>
import CInput from "@/features/ui/СInput.vue";
import CLink from "@/features/ui/CLink.vue";
import CButton from "@/features/ui/СButton.vue";

import validationError from "@/mixins/validationError";
import waitRequest from "@/mixins/waitRequest";
import userLocation from "@/mixins/userLocation";

import BenefitsList from "@/features/ui/common/BenefitsList.vue";

import IconCheck from "@/assets/svg/check.svg";
import IconFalse from "@/assets/svg/false.svg";
import SocialAuthButton from "@/features/containers/SocialAuthButton.vue";
import Logo from "@/assets/svg/logo.svg";
import AppInfo from "@/features/ui/common/AppInfo.vue";

export default {
  components: {
    AppInfo,
    Logo,
    SocialAuthButton,
    BenefitsList,
    CButton,
    CInput,
    CLink,
    IconCheck,
    IconFalse,
  },
  mixins: [validationError, waitRequest, userLocation],
  data() {
    return {
      email: "",
      password: "",
      name: "",
    };
  },
  computed: {
    benefitsList() {
      return [
        { text: this.$t("general.auth.signUp.benefits.list.one") },
        { text: this.$t("general.auth.signUp.benefits.list.two") },
        { text: this.$t("general.auth.signUp.benefits.list.three") },
        { text: this.$t("general.auth.signUp.benefits.list.four") },
      ];
    },

    hasValidationError() {
      return (
        Boolean(this.errors?.collect("password").length) ||
        Boolean(this.errors?.collect("email").length)
      );
    },
  },
  methods: {
    submitForm() {
      if (this.requestInProgress) return;

      return this.waitRequest(() => {
        return this.$post(
          "/auth/signup",
          {
            email: this.email,
            password: this.password,
            name: this.name,
          },

          (data) => {
            this.$saveToken(data.token);
            this.$saveUser(data.user);
            this.$router.push("/");
            this.sendUserLocation();
          },
          () => {},
          this.checkErrors
        );
      }).catch(this.checkErrors);
    },
  },
};
</script>

<style scoped lang="scss">
.font {
  &--policy {
    color: $app-font-color-gray-2;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }
}

.icon {
  &--check {
    width: em(14);
    height: em(10);
  }

  &--false {
    width: em(15);
    height: em(15);
  }
}

@import "~@/assets/scss/vendors/_variables.scss";
.promo {
  background: $app-blue;
  color: $white;
  .middle {
    width: 100%;
    max-width: 344px;
    text-align: left;

    &__logo {
      width: 50%;
      margin-bottom: em(16);
    }
    h2 {
      font-weight: normal;
    }
  }
}

.main {
  width: 344px;

  .logo {
    width: 80%;
    max-height: 4rem;
    margin: 0 auto;
  }
}

.mobile-auth {
  position: relative;
  height: 100%;
  display: flex;
  flex-direction: column;

  @include media-breakpoint-up(md) {
    justify-content: center;
  }

  &__hero {
    background-image: url("/img/backgrounds/auth-login-background.webp");
    background-color: $black;
    height: em(160);
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
  }

  &__logo-wrapper {
    position: relative;
    top: 50%;
    left: 50%;
    transform: translate3d(-50%, -50%, 1px);
  }

  &__logo {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate3d(-50%, -50%, 1px);
    height: em(61);
    width: em(204);
  }

  &__title {
    margin-top: em(20);
    margin-bottom: em(25);

    @include media-breakpoint-up(md) {
      display: none;
    }
  }

  &__title,
  &__content {
    padding-left: em(25);
    padding-right: em(25);
  }

  &__font {
    &--title {
      color: #000;
      font-family: $font-default;
      font-size: em(24);
      font-style: normal;
      font-weight: 700;
      line-height: normal;
    }
  }
}

@include media-breakpoint-down(sm) {
  .w-50 {
    width: 100% !important;
  }
  .main {
    width: 90%;
  }
}
</style>
